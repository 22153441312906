<template>
  <section class="section">
    <div class="level">
      <h2 class="title level-left">
        {{ $t('route.threats-overview') }}
      </h2>
      <range-picker
        ref="rangepicker"
        class="level-right"
        :range.sync="dates"
        :lookup.sync="dateRange"
        :disabled="loading"
        min-hour
      />
    </div>
    <div class="columns">
      <div class="column is-half">
        <box>
          <p slot="header">
            {{ $t('threats.histogram-resource') }}
          </p>
          <chart
            type="timeseries"
            :series="statsSeriesResourceTimeseries"
            :options="statsOptionsAllWithRange"
            :loading="loading"
            lazy
            style="height: 300px"
          />
        </box>
      </div>
      <div class="column is-half">
        <box>
          <p slot="header">
            {{ $t('threats.histogram-src') }}
          </p>
          <chart
            type="timeseries"
            :series="statsSeriesSrcTimeseries"
            :options="statsOptionsAllWithRange"
            :loading="loading"
            lazy
            style="height: 300px"
          />
        </box>
      </div>
    </div>
    <div class="columns">
      <div class="column is-two-thirds">
        <box>
          <p slot="header">
            {{ $t('threats.histogram-origin') }}
          </p>
          <chart
            type="timeseries"
            :series="statsSeriesAllWithRange"
            :options="statsOptionsAllWithRange"
            :loading="loading"
            lazy
            style="height: 300px"
          />
        </box>
      </div>
      <div class="column is-one-third">
        <box>
          <p slot="header">
            {{ $t('threats.pie-severity') }}
          </p>
          <chart
            type="pie"
            :series="statsSeriesSeverity"
            :options="statsOptionsSeverity"
            :loading="loading"
            lazy
            style="height: 300px"
          />
        </box>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <box>
          <p slot="header">
            {{ $t('threats.bar-resource') }}
          </p>
          <chart
            type="bar"
            :series="statsSeriesResource"
            :options="statsOptionsResource"
            :loading="loading"
            lazy
            style="height: 300px"
          />
        </box>
      </div>
      <div class="column is-half">
        <box>
          <p slot="header">
            {{ $t('threats.bar-src') }}
          </p>
          <chart
            type="bar"
            :series="statsSeriesSrc"
            :options="statsOptionsSrc"
            :loading="loading"
            lazy
            style="height: 300px"
          />
        </box>
      </div>
    </div>
  </section>
</template>

<script>
import RangePicker from '@/components/RangePicker'
import Chart from '@/components/Chart'
import { statsSeriesTimeseriesBar, statsSeriesPie, statsSeriesTimeseries } from '@/utils'
import { interpolateYlOrRd, interpolateWarm, interpolateCool, scaleSqrt } from 'd3'
import { ThreatSeverities } from '@/store'

export default {
  name: 'ThreatsOverview',
  components: { RangePicker, Chart },
  data () {
    return {
      dateRange: 'hour-24',
      dates: [],
      loading: false,
      statsAllWithRange: null,
      statsResource: null,
      statsSrc: null,
      statsSeverity: null
    }
  },
  computed: {
    rangeFormat () {
      return '%d/%m/%Y %H:%M'
    },
    categoriesSeverity () {
      return ThreatSeverities
    },
    colorsSeverity () {
      return this.categoriesSeverity.map((_, i) => {
        return interpolateYlOrRd(i / this.categoriesSeverity.length)
      })
    },
    threatScale () {
      return scaleSqrt()
        .domain([0, 30, 10000])
        .range([0, 20, 200])
    },
    statsSeriesAllWithRange () {
      if (!this.statsAllWithRange || !this.statsAllWithRange.length) {
        return {}
      }

      return statsSeriesTimeseries(this.statsAllWithRange, null, { scale: this.threatScale })
    },
    statsSeriesResource () {
      if (!this.statsResource) {
        return {}
      }

      return statsSeriesTimeseriesBar(this.statsResource, null, 'Threats', { scale: this.threatScale })
    },
    statsSeriesSrc () {
      if (!this.statsSrc || !this.statsSrc.length) {
        return {}
      }

      return statsSeriesTimeseriesBar(this.statsSrc, null, 'Threats', { scale: this.threatScale })
    },
    statsSeriesResourceTimeseries () {
      if (!this.statsResource || !this.statsResource.length) {
        return {}
      }

      return statsSeriesTimeseries(this.statsResource, null, { scale: this.threatScale })
    },
    statsSeriesSrcTimeseries () {
      if (!this.statsSrc || !this.statsSrc.length) {
        return {}
      }

      return statsSeriesTimeseries(this.statsSrc, null, { scale: this.threatScale })
    },
    statsSeriesSeverity () {
      if (!this.statsSeverity) {
        return {}
      }

      return statsSeriesPie(this.statsSeverity, this.categoriesSeverity, 'Threats')
    },
    statsOptionsAllWithRange () {
      const self = this
      return {
        axis: {
          x: {
            tick: {
              format: this.rangeFormat
            }
          },
          y: {
            tick: {
              format (d) {
                return Math.round(self.threatScale.invert(d))
              }
            }
          }
        }
      }
    },
    statsOptionsResource () {
      const self = this
      return {
        axis: {
          y: {
            tick: {
              format (d) {
                return Math.round(self.threatScale.invert(d))
              }
            }
          }
        },
        data: {
          color (color, d) {
            if (typeof d.index === 'undefined') {
              return color
            }

            return interpolateWarm((d.index + 1) / 10)
          }
        }
      }
    },
    statsOptionsSrc () {
      const self = this
      return {
        axis: {
          y: {
            tick: {
              format (d) {
                return Math.round(self.threatScale.invert(d))
              }
            }
          }
        },
        data: {
          color (color, d) {
            if (typeof d.index === 'undefined') {
              return color
            }

            return interpolateCool((d.index + 1) / 10)
          }
        }
      }
    },
    statsOptionsSeverity () {
      return {
        tooltip: {
          format: {
            value: function (value, ratio, id) {
              return value
            }
          }
        }
      }
    }
  },
  watch: {
    dates (v) {
      if (!v || !v.length) {
        return
      }

      this.loadStatsAllWithRange()
      this.loadStatsResource()
      this.loadStatsSrc()
      this.loadStatsSeverity()
    }
  },
  methods: {
    loadStatsAllWithRange () {
      this.loading = true
      return this.$http.get(`/api/v1/stats?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}&range=auto`)
        .then(body => {
          if (!body || !body.data) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return
          }

          this.statsAllWithRange = data.data
          this.loading = false
        })
    },
    loadStatsSeverity () {
      this.loading = true
      return this.$http.get(`/api/v1/stats/severity?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}`)
        .then(body => {
          if (!body || !body.data) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return
          }

          this.statsSeverity = data.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadStatsResource () {
      this.loading = true
      return this.$http.get(`/api/v1/stats/resource?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}&range=auto&limit=10`)
        .then(body => {
          if (!body || !body.data) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return
          }

          this.statsResource = data.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadStatsSrc () {
      this.loading = true
      return this.$http.get(`/api/v1/stats/src?from=${this.dates[0].toISOString()}&to=${this.dates[1].toISOString()}&range=auto&limit=10`)
        .then(body => {
          if (!body || !body.data) {
            return
          }

          return body.data
        })
        .then(data => {
          if (!data) {
            return
          }

          this.statsSrc = data.data
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
